import React from 'react'

// Module
import AnchorLink from 'react-anchor-link-smooth-scroll'

// Classes
const ParagraphClassWhite = 'dib relative w-100 measure-wide white fw4 mb4'
const ParagraphClass = 'dib relative w-100 measure-wide gray fw4 mb4'

const PageHeader = class extends React.Component {
  render() {
    return (
      <div className={`db center mw8 pa4 ${this.props.colour}`}>
        <h1 className="dib relative w-100 w-60-l f2 f1-l fw5 mt0 mb2">{this.props.heading}</h1>
        <hr className="dib relative w-100 bb b--inherit mv4 mv5-l o-20" />
        <div className="flex justify-between top-xs w-100">
          <p className={`${this.props.white ? ParagraphClassWhite : ParagraphClass} preline mt0`}>
            {this.props.description}
          </p>
          {this.props.href ? (
            <AnchorLink href={this.props.href}>
              <img
                className="dib relative w1-5 h1-5 tr bounce"
                src={this.props.white ? '/icons/scroll-down-white.svg' : '/icons/scroll-down.svg'}
                alt="Scroll down"
              />
            </AnchorLink>
          ) : null}
        </div>
        {this.props.button ? (
          <a
            className={`dib relative w-100 w-auto-l brand-red tc ph3 pv2 br-pill b link dim pointer ${this.props.buttonBG}`}
            target={this.props.blank ? '_blank' : '_self'}
            href={this.props.link}
          >
            {this.props.cta}
          </a>
        ) : null}
      </div>
    )
  }
}

export default PageHeader
