import React from 'react'

import { graphql } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

// Modules
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Swiper, Slide } from 'react-dynamic-swiper'
import 'react-dynamic-swiper/lib/styles.css'

// Components
import PageHeader from '../components/page-header'
import GraphQLErrorList from '../components/graphql-error-list'
import Layout from '../containers/layout'
import SEO from '../components/seo'

export const query = graphql`
  query AboutPageQuery {
    about: sanityAbout(_id: { regex: "/(drafts.|)aboutPage/" }) {
      joinDescription
      teamDescription
      joinCTALink
      perksTitle
      inclusion {
        asset {
          _id
        }
      }
      culture {
        asset {
          _id
        }
      }
      joinCTA
      teamImage {
        asset {
          _id
        }
      }
      teamCTA
      title2
      title1
      title
      perks {
        description
        title
        icon {
          asset {
            _id
          }
        }
      }
      body2
      body
      team
      join
    }
  }
`

// Classes
const HeadingClass = 'dib relative w-100 w-60-ns f2 fw5 mt0 mb2'
const ParagraphClass = 'dib relative w-100 measure-wide gray fw4 mb4'
const ButtonClass = 'dib relative w-100 w-auto-l brand-red tc ph3 pv2 br-pill b link dim pointer'

const AboutPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const about = (data || {}).about

  if (!about) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={about.title} />
      <article className="about lh-copy" id="about">
        {/* About main */}
        <section
          className="dt relative vh-100 w-100 bg-gradient black lh-copy overflow-hidden f5"
          id="about"
        >
          <div className="dtc v-mid w-100 relative">
            <PageHeader description={about.body} heading={about.title1} href="#inclusion" />
          </div>
        </section>

        {/* Inclusion */}
        <section
          className="dib relative w-100 bg-white black lh-copy f5 overflow-hidden"
          id="inclusion"
        >
          <div className="db center mw8 ph4 pt6 pb4">
            <h2 className={HeadingClass}>{about.title2}</h2>
            <p className={`${ParagraphClass} preline`}>{about.body2}</p>
          </div>
          {/* Images */}
          <div className="flex justify-between top-xs relative w-100 about-grid">
            <div className="left">
              <img
                className="dib relative w-100 fit"
                alt="Lake for everyone"
                src={imageUrlFor(buildImageObj(about.inclusion[0]))}
              />
            </div>
            <div className="center">
              <img
                className="dib relative w-100 fit"
                alt="Lake for everyone"
                src={imageUrlFor(buildImageObj(about.inclusion[1]))}
              />
              <img
                className="dib relative w-100 fit"
                alt="Lake for everyone"
                src={imageUrlFor(buildImageObj(about.inclusion[2]))}
              />
            </div>
            <div className="right">
              <img
                className="dib relative w-100 fit"
                alt="Lake for everyone"
                src={imageUrlFor(buildImageObj(about.inclusion[3]))}
              />
            </div>
          </div>
        </section>

        {/* Story */}
        <section className="dib relative w-100" id="team">
          <div className="db center mw8 ph4 pv6">
            <h3 className={HeadingClass}>{about.team}</h3>
            <p className={`${ParagraphClass} preline`}>{about.teamDescription}</p>
            <div className="dib relative w-100 mb4">
              <AnchorLink className={`bg-brand-pink ${ButtonClass}`} href="#join">
                More about our culture
              </AnchorLink>
            </div>
            <img
              className="dib relative w-100"
              src={imageUrlFor(buildImageObj(about.teamImage))}
              alt={about.team}
            />
          </div>
        </section>

        {/* Careers / join */}
        <section className="dib relative w-100 bg-gradient" id="join">
          <div className="db center mw8 ph4 pt6 pb2">
            <h4 className={HeadingClass}>{about.join}</h4>
            <p className={`${ParagraphClass} preline`}>{about.joinDescription}</p>
            <div className="dib relative w-100 mb4">
              <a
                className={`bg-white ${ButtonClass}`}
                target="_blank"
                href={about.joinCTALink}
                rel="noopener noreferrer"
              >
                {about.joinCTA}
              </a>
            </div>
          </div>
          <Swiper
            swiperOptions={{
              slidesOffsetAfter: 0,
              slidesPerView: 1,
              spaceBetween: 20,
              breakpoints: {
                // when window width is <= 480px
                480: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                // when window width is <= 1024px
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 20
                }
              },
              autoplay: {
                delay: 2000,
                disableOnInteraction: true
              },
              keyboard: {
                enabled: true
              }
            }}
            pagination={false}
            prevButton={
              <img className="prev-next prev" src="/icons/scroll-down-white.svg" alt="Previous" />
            }
            nextButton={
              <img className="prev-next next" src="/icons/scroll-down-white.svg" alt="Next" />
            }
          >
            {about.culture.map(image => (
              <Slide key={Math.random()}>
                <img
                  className="dib relative w-100 fit"
                  alt="Our culture"
                  src={imageUrlFor(buildImageObj(image))}
                />
              </Slide>
            ))}
          </Swiper>
        </section>

        {/* Perks */}
        <section className="dib relative w-100" id="perks">
          <div className="db center mw8 ph4 pt6 pb2">
            <h5 className={HeadingClass}>{about.perksTitle}</h5>
            {about.perks.map((object, index) => (
              <div className="dib relative w-100 pv4 bb b--silver" key={`perk-${index}`}>
                <div className="dib flex-l top-xs">
                  <span className="dib relative w3 h3 br-pill bg-brand-pink tc mr4 mr5-l pa0 pa2-l overflow-hidden mb3 mb0-l">
                    {object.icon ? (
                      <img
                        className="dib relative w-100 fit"
                        src={imageUrlFor(buildImageObj(object.icon))}
                      />
                    ) : null}
                  </span>
                  <span className="dib relative w-100 measure tl">
                    <strong className="dib relative w-100 f4 fw4">{object.title}</strong>
                    <p className="dib relative w-100 dark-gray fw4">{object.description}</p>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default AboutPage
